* {
  font-family: "Inter";
}

.job-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
}

.job-page-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 0px;
  gap: 32px;
  width: 30%;
  min-width: 640px;
}

.section-name-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0.1, 0.1, 0.1, 0.1);
}

.section-name-header-wrapper {
  padding: 12px 24px;
  border-radius: 8px 8px 0px 0px;
  background-color: #6772f4;
  color: white;
  font-size: 16px;
}

.section-name-content-wrapper {
  padding: 18px 24px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quize-container {
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0.1, 0.1, 0.1, 0.1);
  transition: border-color 0.25s ease-in-out;
}

.quize-container.required {
  border-color: red;
}

.section-header-wrapper {
  font-size: 16px;
  margin-bottom: 16px;
}

.section-content-wrapper {
  position: relative;
  border-radius: 0px 0px 8px 8px;
}

.required-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  line-height: 1;
  position: absolute;
}

.required-quize {
  color: red;
}

.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
}

.quize-radio-input {
  width: 20px;
  height: 20px;
  accent-color: #6772f4;
}

.quize-text-input {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #3434348c !important;
  color: #262626e0;
  width: 100%;
  padding: 8px 0px;
  transition: border-bottom 0.2s ease-in-out;
}

.quize-text-input:active,
.quize-text-input:focus {
  border-bottom: 1px solid #6772f4 !important;
}

.quize-date-input,
.quize-date-input:hover {
  padding: 8px 0px;
  border-bottom: 1px solid #3434348c;
  transition: border-bottom 0.2s ease-in-out;
}

.quize-date-input:active,
.quize-date-input:focus {
  border-bottom: 1px solid #6772f4 !important;
}

.quize-file-input-wrapper {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #3434348c;
}

.quize-file-input-wrapper:active,
.quize-file-input-wrapper:focus,
.quize-file-input-wrapper:hover {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #6772f4;
}

.quize-file-input-img {
  width: 32px;
}

.quize-file-input-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.toast {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: white;
  color: black;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;
  display: grid;
  grid-template-columns: 1.3fr 6fr 0.5fr;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.18);
}

.container-1,
.container-2 {
  align-self: center;
}
.container-1 svg {
  width: 35px;
  height: 35px;
}
.success i {
  color: #47d764;
}
.container-2 p:first-child {
  color: #101020;
  font-weight: 600;
  font-size: 16px;
}
.container-2 p:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #656565;
}
.toast button {
  align-self: flex-start;
  background-color: transparent;
  font-size: 25px;
  color: #656565;
  line-height: 0;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .job-page-container {
    padding: 16px 0px;
  }
}

@media screen and (max-width: 680px) {
  .job-page-content {
    width: 85%;
    min-width: unset;
  }
}
