@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

@keyframes circleAnimation {
  0% {
    top: -7px;
    width: 14px;
    height: 14px;
    opacity: 0;
  }

  10% {
    top: -7px;
    width: 14px;
    height: 14px;
    opacity: 1;
  }

  50%,
  60% {
    top: calc(100% - 13px);
    width: 26px;
    height: 26px;
    opacity: 1;
  }

  10%,
  50% {
    opacity: 1;
  }

  64%,
  70%,
  90%,
  100% {
    opacity: 0;
  }
}

.circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border-radius: 50%;
  animation: circleAnimation 5s infinite;
}

.scroll::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
  scrollbar-width: none;
}

li {
  list-style-type: none;
}

section {
  /*   background: red; */
}

/* Marquee styles */
.marquee {
  --gap: 0rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  width: 100%;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: fit-content;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Enable animation */
.enable-animation .marquee__content {
  animation: scroll 17s linear infinite;
}

/* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
.marquee--fit-content {
  max-width: fit-content;
}

/* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
.marquee--pos-absolute .marquee__content:last-child {
  position: absolute;
  top: 0;
  left: 0;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation .marquee--pos-absolute .marquee__content:last-child {
  animation-name: scroll-abs;
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }

  to {
    transform: translateX(0);
  }
}

/* Other page demo styles */
.marquee__content>* {
  flex: 0 0 auto;
  font-size: 26px;
  font-weight: 600;
  margin: 2px;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 1fr 125px;
  width: 100%;
}

.header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 120px;
}

.header-logo-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

.logo-project-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.header-contact-us-btn {
  background-color: white;
  border: 1px solid #000000;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  width: 180px;
  border-radius: 20px;
}

.home-section-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
}

.home-section-big-text-block {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-top: 52px;
}

.home-section-design-text {
  font-weight: 700;
  color: #6772f4;
  font-size: 128px;
  text-transform: uppercase;
  line-height: 112px;
}

.home-section-development-text {
  background-image: url("./assets/img/Common/DevelopmentBackgroundImage.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: fit-content;
  line-height: 92px;
  background-position: center;
  color: #ffffff;
  font-size: 128px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 97px;
}

.home-section-security-text {
  font-weight: 700;
  color: #000000;
  font-size: 128px;
  text-transform: uppercase;
  line-height: 112px;
}

.home-section-bottom-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 60px;
  padding: 0 80px 0 120px;
}

.home-section-bottom-description-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  white-space: pre-line;
}

.home-section-bottom-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #000000;
}

.navigation-wrapper {
  height: 100vh;
  box-shadow: 0px 0px 5px 0px #939393e5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 124px;
  z-index: 2;
}

.navigation-item {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #969696;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.navigation-item.active {
  color: #000000;
}

.technologies-section-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 120px;
}

.technologies-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

.technologies-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
}

.technologies-subtitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  width: 200px;
}

.technologies-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px;
}

.technologies-item {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  border-bottom: 1px solid #000000;
}

.projects-section-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 120px;
  padding-top: 80px;
}

.projects-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.projects-section-title {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
}

.projects-section-subtitle {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  width: 200px;
}

.projects-section-list {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 32px;
}

.project-item-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;
  height: 524px;
  position: relative;
}

.projects-slides-pagination-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  gap: 24px;
}

.project-slide-pagination-item {
  width: 22px;
  height: 22px;
  background-color: #d9d9d9;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
}

.project-slide-pagination-item.active {
  background-color: #969696;
  transition: 0.2s;
}

.project-item-image {
  height: 100%;
  box-shadow: 0px 0px 20px 0px #00000034;
  border-radius: 24px;
}

.project-item-learn-more-btn {
  min-width: 149px;
  height: 40px;
  border-radius: 20px;
  padding: 8px 16px;
  border: 1px solid #000000;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 32px;
  right: 16px;
  gap: 16px;
}

.project-item-learn-more-btn-white {
  min-width: 149px;
  height: 40px;
  border-radius: 20px;
  padding: 8px 16px;
  border: 1px solid #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 32px;
  right: 16px;
  gap: 16px;
}

.project-item-learn-more-btn-white img {
  filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(281deg) brightness(108%) contrast(102%);
}

.project-item-name {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  position: absolute;
  bottom: 115px;
  width: 100%;
  padding: 0 16px;
  text-align: center;
  text-transform: uppercase;
}

.project-item-nda {
  position: absolute;
  top: -35px;
  right: -30px;
  z-index: 2;
}

.services-section-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 120px;
  padding-top: 80px;
}

.services-section-header {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
}

.services-section-list-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  margin-top: 60px;
}

.service-block-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.service-block-item-title {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.service-block-item-description {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.testimonials-section-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 120px;
  padding-top: 80px;
}

.testimonials-section-list-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
}

.testimonial-block-item {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.testimonial-block-item-heading {
  display: flex;
  align-items: center;
  gap: 40px;
  height: 60px;
  padding-left: 40px;
}

.testimonial-block-item-name {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #000000;
}

.testimonial-block-item-vertical-line {
  height: 100%;
  width: 1px;
  background-color: #000000;
}

.testimonial-block-item-text {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-indent: 36px;
}

.contact-us-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 120px;
  padding-top: 80px;
}

.contact-us-header-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.contact-us-form-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: -20px;
}

.contact-us-input-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 380px;
  width: 100%;
}

.contact-us-input-label {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.contact-us-input {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 1px solid #3434348c !important;
  color: #262626e0;
  width: 100%;
  padding: 8px;
}

.contact-us-input.error {
  border-bottom: 1px solid #ff0000 !important;
}

textarea.contact-us-input {
  border: 1px solid #3434348c !important;
}

textarea.contact-us-input.error {
  border: 1px solid #ff0000 !important;
}

.contact-us-input::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3434348c;
}

.contact-us-attach-file-block {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #3434348c !important;
}

.contact-us-attach-file-input {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.contact-us-send-button {
  width: 169px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #000000;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-top: 16px;
}

.hamburger-menu-mobile {
  display: none;
}

.home-section-contact-us-btn-mobile {
  display: none;
}

.home-section-bottom-links-mobile {
  display: none;
}

.project-page-wrapper {
  display: grid;
  grid-template-columns: 1fr 125px;
  width: 100%;
}

.project-page-content {
  display: flex;
  flex-direction: column;
  padding: 50px 120px;
  padding-right: 32px;
  overflow-x: hidden;
}

.project-content-title {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
}

.project-content-header {
  position: relative;
  width: 100%;
  margin-top: 32px;
  box-shadow: 0px 0px 20px 0px #00000034;
  border-radius: 24px;
}

.project-content-name {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
  position: absolute;
  bottom: 32px;
  right: 16px;
}

.project-content-about-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-top: 32px;
}

.project-content-about-project.full-width {
  grid-template-columns: 1fr;
}

.project-content-about-column {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.project-solved-challenges-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 100px;
}

.solved-challenges-block-title {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e;
  text-transform: uppercase;
}

.solved-challenges-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.solved-challenge-item {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.solved-challenges-icon {
  width: 314px;
  position: absolute;
  top: 0;
  right: 0;
}

.project-technologies-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 100px;
}

.project-technology-block {
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: space-between;
}

.project-technology-block.ordinary {
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: center;
}

.project-technology-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 30%;
}

.project-technology-name-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
  padding: 8px;
  gap: 24px;
}

.project-technology-name {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.project-technology-usage {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.project-testimonial-list-block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 100px;
}

.mobile-solved-challenges-read-more {
  display: none;
}

.mobile-section-underline {
  display: none;
}

.header-logo-icon {
  width: 120px;
}

.contact-us-logo {
  width: 120px;
}

.privacy-policy-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.privacy-policy-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.privacy-policy-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
}

.privacy-policy-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.privacy-policy-section-title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #000000;
}

.privacy-policy-section-text {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.min-height-image {
  min-height: 300px;
}

.project-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

@media (max-width: 1200px) {
  .header-contact-us-btn {
    display: none;
  }

  .header-contact-us-btn.privacy {
    display: block;
  }

  .hamburger-menu-mobile {
    display: block;
  }

  .navigation-wrapper {
    display: none;
  }

  .header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .main-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .home-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 16px 0;
    min-height: 100vh;
  }

  .logo-project-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0px;
  }

  .home-section-big-text-block {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 120px;
  }

  .home-section-design-text {
    font-weight: 700;
    color: #6772f4;
    font-size: 47px;
    text-transform: uppercase;
    line-height: 50px;
  }

  .home-section-development-text {
    background-image: url("./assets/img/Common/DevelopmentBackgroundImage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: fit-content;
    background-position: center;
    color: #ffffff;
    font-size: 47px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50px;
  }

  .home-section-security-text {
    font-weight: 700;
    color: #000000;
    font-size: 47px;
    text-transform: uppercase;
    line-height: 50px;
  }

  .home-section-bottom-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    margin-top: 24px;
    padding: 0 0 0 16px;
  }

  .home-section-bottom-description-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }

  .home-section-bottom-links {
    display: none;
  }

  .home-section-contact-us-btn-mobile {
    display: block;
    min-width: 155px;
    height: 44px;
    border-radius: 20px;
    border: 1px solid #000000;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 60px;
    padding: 0 16px;
  }

  .home-section-bottom-links-mobile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .technologies-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 16px;
    padding-top: 0px;
    padding-bottom: 0;
  }

  .technologies-section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0px;
  }

  .technologies-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }

  .technologies-subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    width: 200px;
  }

  .technologies-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;
  }

  .technologies-item {
    width: unset;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    border-bottom: 1px solid #000000;
  }

  .projects-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .services-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .testimonials-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .services-section-list-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-top: 32px;
  }

  .contact-us-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 40px;
  }

  .projects-section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    padding-top: 0px;
  }

  .projects-section-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
    /* overflow: hidden; */
  }

  .project-item-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: unset;
    position: relative;
    margin-bottom: 70px;
  }

  .testimonial-block-item-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    height: unset;
    width: fit-content;
    padding-left: 0px;
  }

  .project-item-name {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    position: absolute;
    bottom: 24px;
    width: 100%;
    padding: 0 16px;
    text-align: center;
    text-transform: uppercase;
  }

  .project-item-learn-more-btn {
    min-width: 130px;
    height: 32px;
    border-radius: 20px;
    padding: 8px 16px;
    border: 1px solid #000000;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -54px;
    left: 50%;
    transform: translateX(-50%);
  }

  .project-item-nda {
    position: absolute;
    top: -30px;
    right: -25px;
    z-index: 2;
  }

  .services-section-header {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }

  .testimonial-block-item-vertical-line {
    height: 1px;
    width: 100%;
    background-color: #000000;
  }

  .testimonial-block-item-name {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #000000;
  }

  .testimonial-block-item {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .testimonial-block-item-text {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-indent: 0px;
  }

  .testimonials-section-list-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .contact-us-form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
  }

  .contact-us-input-label {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .contact-us-input {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid #3434348c !important;
    color: #262626e0;
    width: 100%;
    padding: 8px;
  }

  .contact-us-input::placeholder {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #3434348c;
  }

  .contact-us-input-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 380px;
    width: 100%;
  }

  .contact-us-send-button {
    width: 130px;
    height: 32px;
    border-radius: 20px;
    border: 1px solid #000000;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-top: 8px;
  }

  .service-block-item-title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .service-block-item-description {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .service-block-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .project-page-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .project-page-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-right: 16px;
    overflow-x: hidden;
  }

  .project-content-title {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .project-content-header {
    position: relative;
    width: 100%;
    margin-top: 16px;
  }

  .project-content-name {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
    position: absolute;
    bottom: 0px;
    right: 50%;
    transform: translateX(50%);
  }

  .project-content-about-project {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 24px;
  }

  .project-content-about-column {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .solved-challenges-block-title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #1e1e1e;
    text-transform: uppercase;
  }

  .solved-challenges-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }

  .solved-challenge-item {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .solved-challenges-icon {
    width: 129px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .mobile-solved-challenges-read-more {
    display: block;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #969696;
    text-decoration: underline;
    position: absolute;
    bottom: -145px;
    left: 0;
  }

  .project-technology-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    justify-content: space-between;
  }

  .project-technology-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;
    width: 100%;
  }

  .project-solved-challenges-block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 0px;
  }

  .project-solved-challenges-block.short-text {
    padding-bottom: 150px;
  }

  .project-solved-challenges-block.without-padding {
    padding-bottom: 0px;
  }

  .main-mobile-section-underline {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #969696ae;
    margin: 70px 0;
  }

  .mobile-section-underline {
    display: flex;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #969696ae;
    margin: 70px 0;
    margin-left: -16px;
  }

  .project-technologies-block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 0px;
  }

  .project-testimonial-list-block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 0px;
  }

  .header-logo-icon {
    width: 60px;
  }

  .privacy-policy-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .privacy-policy-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .min-height-image {
    min-height: 150px;
  }
}