@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Inter";
}

input,
textarea,
input:hover,
textarea:hover,
input:focus,
textarea:focus,
input:focus-visible,
textarea:focus-visible,
input:active {
    border: none;
    outline: none;
    box-shadow: none;
}

li {
    list-style-type: disc;
    margin-left: 30px;
    padding-left: 16px;
}